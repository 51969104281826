// @flow

import React from "react";
import {
    Layout,
    InspectionPointLayout,
    InspectionPointHistory,
    withPrivateRoute,
} from "@containers";

/**
 * Home Page
 */
const History = (props: *) => (
    <Layout {...props}>
        <InspectionPointLayout>
            <InspectionPointHistory />
        </InspectionPointLayout>
    </Layout>
);

export default withPrivateRoute(
    History,
    "/inspectiepunt/:inspectionPointId/historiek",
);
